import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import TLink from '../../layout/TLink';
import AppContext from '../../store/AppContext';
import Tracking from '../../utils/tracking';
import { staticPage } from '../../utils/util';
import KoiButton from '../koi/KoiButton';

import AccountMenu from './AccountMenu';
import TruepathLogo from './TruepathLogo';

import './DesktopHeader.scss';

export default function DesktopHeader({ children, superUser }) {
  const [state] = useContext(AppContext);
  const { currentUser } = state;
  const location = useLocation();

  const isReferredPage =
    location.pathname.includes('/invite/') && !currentUser?.registered;

  const renderPublicMenu = () => {
    return (
      <>
        {!isReferredPage && (
          <a
            className="nav-item"
            href={staticPage('/resume')}
            target="_blank"
            rel="noreferrer"
          >
            Resume
          </a>
        )}

        <a
          className="nav-item"
          href={staticPage('/pricing')}
          target="_blank"
          rel="noreferrer"
        >
          Pricing
        </a>

        {isReferredPage && (
          <>
            <a className="nav-item" href={'/login'}>
              Log In
            </a>

            <KoiButton
              className="cta-button"
              variant="primary"
              as={Link}
              to={'/resume-builder'}
              onClick={() =>
                Tracking.trackUserClicked({
                  widget_name: 'Get started',
                  from: 'referrer_invited_page',
                  section: 'header',
                })
              }
            >
              Get started
            </KoiButton>
          </>
        )}
      </>
    );
  };

  const renderPrivateMenu = () => {
    if (superUser) {
      return (
        <TLink
          className="nav-item"
          to="/logout"
          properties={{ widget_name: 'Pricing' }}
        >
          Log out
        </TLink>
      );
    }
    return (
      <>
        <TLink
          className="nav-item"
          to="/plans"
          properties={{ widget_name: 'Pricing' }}
        >
          Pricing
        </TLink>

        {currentUser.onboarding_step === 'dashboard' && (
          <TLink
            className="nav-item"
            to="/dashboard?tab=documents"
            properties={{ widget_name: 'Dashboard' }}
          >
            My dashboard
          </TLink>
        )}

        <AccountMenu />
      </>
    );
  };

  return (
    <div className="__desktop-header">
      <div className="flex">
        <TruepathLogo />
        {superUser && <p className="admin-label">ADMIN</p>}
      </div>

      {children}

      <div className="menu-links">
        {!currentUser?.registered ? renderPublicMenu() : renderPrivateMenu()}
      </div>
    </div>
  );
}
