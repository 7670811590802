import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Bugsnag from '@bugsnag/js';

import { useAppContext } from '../store/AppContext';
import { api } from '../utils/api';

export default function useOnboarding(pageStep, options = {}) {
  const { appState } = useAppContext();
  const [searchParams] = useSearchParams();
  const { currentUser, loggedIn } = appState;
  const navigate = useNavigate();

  const [ready, setReady] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const handleNavigateToDownloadPage = async () => {
    try {
      const { data } = await api.get('/job_opportunities');
      const lastJobOpportunity = data.length ? data[0] : {};
      const resumeId = lastJobOpportunity?.resume?.id;
      if (resumeId) {
        return navigate(`/view-resume/${resumeId}`);
      } else {
        return navigate('/resume-builder', { replace: true });
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  };

  const handleOnboardedUser = () => {
    if (searchParams.get('return_to_pricing')) {
      return navigate('/plans', { replace: true });
    } else {
      return navigate('/dashboard', { replace: true });
    }
  };

  // Check the user onboarding step and redirect to the appropriate page
  useEffect(() => {
    const checkPaths = () => {
      // if the resource is ready, do nothing
      if (ready) {
        return;
      }

      // if user is not logged in, the resource is ready to be rendered
      if (loggedIn === false) {
        setReady(true);

        if (options.skipRedirect) {
          return;
        }

        // If the page is the base page (my.truepath.ai), users should redirect to login page if they are not logged in
        pageStep === 'landing_page' && navigate('/login');
        return;
      }

      // If user is logged in and the user data if fetched, check the onboarding step of the user
      if (currentUser) {
        const { onboarding_step } = currentUser;

        setReady(true);

        // If the page step is the same as the user onboarding step, or if the redirect is skipped, do nothing
        if (pageStep === onboarding_step || options.skipRedirect) {
          return;
        }

        setRedirecting(true);

        if (onboarding_step === 'resume_builder') {
          // For legacy users without the onboarding_step: 'download_resume'
          if (
            currentUser.resume_builder_step === 'review_step' &&
            currentUser.resume_builder_view === 'CHOOSE_TEMPLATE' &&
            currentUser.resume_template_id
          ) {
            return handleNavigateToDownloadPage();
          }

          return navigate('/resume-builder', { replace: true });
        }

        if (onboarding_step === 'download_resume') {
          return handleNavigateToDownloadPage();
        }

        if (onboarding_step === 'dashboard') {
          handleOnboardedUser();
        }
      }
    };

    checkPaths();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, loggedIn, ready, pageStep, navigate, options]);

  return { ready, redirecting };
}
