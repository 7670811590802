import Modal from 'react-bootstrap/Modal';

import Paywall from '../Paywall/Paywall';

import './PurchaseModal.scss';

export default function PurchaseModal({ show = false, onHide, onConfirm }) {
  return (
    <Modal className={'__paywall-modal'} centered show={show} onHide={onHide}>
      <Modal.Body
        style={{
          padding: '0px',
          borderRadius: '5%',
        }}
      >
        <Paywall onHide={onHide} />
      </Modal.Body>
    </Modal>
  );
}
