export const PAYMENT_ERROR =
  'There is an issue processing your payment method, please verify your card information. Your card was not charged.';

export const REFERRAL_BONUS = 'REFERRER BONUS';

export const PLAN_FEATURES = [
  'Unlimited customized resumes and cover letters',
  'Psychometric assessment',
  'In-demand skills recommendations',
  'ATS-optimized templates',
];

export const STRIPE_STYLE = {
  base: {
    fontSize: '16px',
    fontFamily: "'Mulish', sans-serif",
    color: '#424770',
    '::placeholder': {
      color: '#bbbbbb',
    },
  },
  invalid: {
    color: '#9e2146',
  },
};
