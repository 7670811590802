import { useContext, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/koi/arrow-left.svg';
import { ReactComponent as AssessmentIcon } from '../../assets/icons/koi/assessment-icon.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/koi/checkmark.svg';
import { ReactComponent as InDemandIcon } from '../../assets/icons/koi/in-demand-skills-icon.svg';
import { ReactComponent as InvalidIcon } from '../../assets/icons/koi/invalid-icon.svg';
import { ReactComponent as TemplatesIcon } from '../../assets/icons/koi/optimized-templates-icon.svg';
import { ReactComponent as ResumeIcon } from '../../assets/icons/koi/resume-paywall-icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/koi/small-circle-check.svg';
import { ReactComponent as UnlimitedIcon } from '../../assets/icons/koi/unlimited-icon.svg';
import { ReactComponent as ValidIcon } from '../../assets/icons/koi/valid-icon.svg';
import { ReactComponent as PoweredByStripe } from '../../assets/pricing/powered_stripe_black.svg';
import TruepathInput from '../../common/TruepathInput/TruepathInput';
import { CustomConversionEvents } from '../../constants/featureFlags';
import {
  PAYMENT_ERROR,
  PLAN_FEATURES,
  REFERRAL_BONUS,
  STRIPE_STYLE,
} from '../../constants/payment';
import useGTM from '../../hooks/useGTM';
import KoiButton from '../../layout/koi/KoiButton';
import KoiTitle, { Highlight } from '../../layout/koi/KoiTitle';
import KoiToast from '../../layout/koi/KoiToast';
import AppContext from '../../store/AppContext';
import { api } from '../../utils/api';
import { addDaysToDate } from '../../utils/dates';
import { ENV_TEST, getEnvSetting } from '../../utils/envSettings';
import { getDiscountedPrice } from '../../utils/formatter';
import Tracking from '../../utils/tracking';

import SuccessfulPurchase from './SuccessfulPurchase';

import './Paywall.scss';

const ITEMS = [
  {
    icon: <UnlimitedIcon />,
    label: 'Unlimited customized resumes and cover letters',
  },
  {
    icon: <AssessmentIcon />,
    label: 'Psychometric assessment',
  },
  {
    icon: <InDemandIcon />,
    label: 'In-demand skills recommendations',
  },
  {
    icon: <TemplatesIcon />,
    label: 'ATS-optimized templates',
  },
];

const PLANS_FTUE = [
  {
    id: 10,
    free_trial: true,
    statName: 'free trial',
    title: '7-day free trial',
    subTitle: '7-day free trial, then pay $24.90 every month',
    price: '$0',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/now',
    info: '7-day free trial, then pay every month. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Truepath.ai 7-day free trial followed by a monthly subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    type: 'membership',
  },
  {
    id: 10,
    free_trial: true,
    statName: '1 month unlimited',
    title: '1 month',
    subTitle: '7-day free trial, then pay $24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: '7-day free trial, then pay every month. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Truepath.ai 7-day free trial followed by a monthly subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    type: 'membership',
  },
  {
    id: 12,
    free_trial: true,
    statName: '6 month unlimited',
    title: '6 months',
    subTitle: '7-day free trial, then pay $59.40 every 6 months',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: '7 day free trial, then pay upfront every 6 months at 60% off.',
    disclaimer: `You consent to start your Truepath.ai 7-day free trial followed by a 6-month subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    type: 'membership',
  },
];

const PLANS_UNSUSCRIBED = [
  {
    id: 3,
    title: 'Pay as you go',
    statName: 'pay as you go',
    subTitle: '$4.90 per document',
    price: '$4.90',
    fullPrice: '$4.90',
    periodicity: 'one-time',
    period: '/doc',
    phrase: 'Pay as you go',
    info: 'No commitment',
    type: 'single_document',
  },
  {
    id: 10,
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: '$24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: 'No commitment, cancel anytime.',
    disclaimer: `You will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    type: 'membership',
  },
  {
    id: 12,
    title: '6 months',
    statName: '6 month unlimited',
    subTitle: '$59.40 every 6 months',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: '6 month commitment at 60% off.',
    disclaimer: `You will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    type: 'membership',
  },
];

const MOCK_PAYMENT = false;
const reCaptchaSiteKey = getEnvSetting('RECAPTCHA_SITE_KEY');

const Paywall = ({ onHide }) => {
  const [showCardInfo, setShowCardInfo] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [promoCodeStatus, setPromoCodeStatus] = useState('');
  const [coupon, setCoupon] = useState(false);
  const [error, setError] = useState(null);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const ldClient = useLDClient();
  const recaptchaRef = useRef();
  const { trackGTMEvent } = useGTM();
  const stripe = useStripe();
  const elements = useElements();
  const [state, dispatch] = useContext(AppContext);
  const { currentUser } = state;
  const isUnsubscribed = currentUser?.unsubscribed;
  const PLANS = isUnsubscribed ? PLANS_UNSUSCRIBED : PLANS_FTUE;
  const [selectedPlan, setSelectedPlan] = useState(PLANS[0]);
  const subscriptionType = selectedPlan?.type === 'membership';

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    Tracking.trackUserClicked({
      widget_name: 'Confirm and Pay',
    });

    if (MOCK_PAYMENT) {
      return onPaymentSuccessful();
    }

    // Stripe.js has not loaded yet.
    if (!stripe || !elements) {
      return;
    }

    // Get a reference to a mounted CardElement
    const cardElement = elements.getElement(CardNumberElement);
    setProcessingPayment(true);

    // Created on free_trial = true with a payment_method,
    // or before payment when free_trial = false
    const createSubscription = async (price_id, trial = false) => {
      const payload = {
        subscription: {
          price_id,
          trial,
          coupon: coupon?.id,
        },
      };

      if (!ENV_TEST) {
        const captchaToken = await recaptchaRef.current.executeAsync();
        payload.captcha_token = captchaToken;
      } else {
        payload.subscription.mocked = true;
      }

      try {
        const response = await api.post('/payments/subscriptions/', payload);
        setSubscriptionInfo(response.data);
        return response.data;
      } catch (error) {
        setError(PAYMENT_ERROR);
        setProcessingPayment(false);
      }
    };

    // Updated after a valid Stripe card_payment
    const updateSubscription = async (payment_method) => {
      const payload = { subscription: { payment_method } };
      const response = await api.put(
        `/payments/subscriptions/${currentUser.id}`,
        payload,
      );
      return response.data;
    };

    // Create before a Stripe payment
    const createInvoice = async () => {
      const invoice = {
        invoice: {
          price_id: selectedPlan.id,
          coupon: coupon?.id,
        },
      };

      const response = await api.post('/payments/invoices', invoice);
      return response.data;
    };

    const updateInvoice = async (invoice_id) => {
      const { data } = await api.put(`/payments/invoices/${invoice_id}`);
      return data;
    };

    // Charge card on invoices (single purchase) or non-free-trial subscriptions
    const stripeCardPayment = async (secret) => {
      const { error, paymentIntent } = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (error) {
        console.log('[error]', error);
        setError(error.message);
        setProcessingPayment(false);

        return null;
      }

      return paymentIntent.payment_method;
    };

    // After a successful free trial, invoice, or subscription, follow the succesful payment flow:
    const successfulPaymentFlow = async (invoiceId) => {
      const gross_value = parseFloat(
        selectedPlan.fullPrice.replace(/[^0-9.-]+/g, ''),
      );

      const value = coupon
        ? (((100 - coupon.percent_off) / 100) * gross_value).toFixed(2)
        : gross_value;

      Tracking.trackUserAction('Purchase', {
        value,
        currency: 'USD',
        fbp: Cookies.get('_fbp'),
        fbc: Cookies.get('_fbc'),
      });

      const safeValue = value || 29.0;
      const additionalParams = {
        from_view: 'Paywall',
        value: safeValue,
        promo_code: coupon?.promoCode?.toLowerCase() || '',
        plan_id: selectedPlan.id,
        currency: 'USD',
        transaction_id: invoiceId || '',
      };

      Tracking.trackPurchase({
        ...additionalParams,
        value: selectedPlan.free_trial ? 0 : safeValue,
      });

      additionalParams.currentUser = currentUser;
      trackGTMEvent('completePurchase', additionalParams);

      ldClient.track(CustomConversionEvents.COMPLETE_PURCHASE, {
        plan: selectedPlan.title,
      });

      if (coupon?.promoCode === REFERRAL_BONUS) {
        Tracking.trackConditionMet({
          widget_name: 'Redeem Coupon Code',
          context: 'Referrals',
        });
      }

      onPaymentSuccessful();
      setProcessingPayment(false);
    };

    if (subscriptionType) {
      /* HANDLE FREE TRIAL */
      if (selectedPlan.free_trial) {
        try {
          const { paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              name: currentUser.user_name,
              email: currentUser.email,
            },
          });

          await api.post('/payments/payment_methods', {
            stripe_id: paymentMethod.id,
          });
          const trialSubscription = await createSubscription(
            selectedPlan.id,
            true,
          );
          return successfulPaymentFlow(trialSubscription.stripe_id);
        } catch (error) {
          setProcessingPayment(false);
          setError(PAYMENT_ERROR);
          return;
        }
      }

      /* HANDLE NORMAL SUBSCRIPTION */
      // 1. create the subscription
      let newSubscription;
      try {
        newSubscription = await createSubscription(selectedPlan.id, false);
      } catch {
        setError(PAYMENT_ERROR);
        setProcessingPayment(false);
        return;
      }

      // 2. stripe confirms and charges the card
      let payment_method = 'pm';
      if (coupon === null || coupon.percent_off !== 100) {
        payment_method = null;
        payment_method = await stripeCardPayment(newSubscription.client_secret);
      }

      // 3. update subscription payment method
      if (payment_method) {
        try {
          const { status, stripe_id } =
            await updateSubscription(payment_method);

          if (status === 'active') {
            successfulPaymentFlow(stripe_id);
          } else {
            throw new Error('Invoice could not be updated or wrong status');
          }
        } catch (error) {
          setError(error.message);
          setProcessingPayment(false);
        }
      }
    } else {
      // Regular Invoice (one-time payment)

      // 1. create (post) /invoice
      let invoice;

      try {
        invoice = await createInvoice();
      } catch {
        setError(PAYMENT_ERROR);
        setProcessingPayment(false);
        return;
      }

      // 2. Stripe confirms and charges the card
      const payment_method = await stripeCardPayment(invoice.client_secret);

      // 3. Invoice was paid successfully, update (put) /invoice
      try {
        if (payment_method) {
          const { status } = await updateInvoice(invoice.id);

          if (status === 'paid') {
            successfulPaymentFlow(invoice.stripe_id);
          } else {
            throw new Error('Invoice could not be updated or wrong status');
          }
        }
      } catch {
        setError(error.message);
        setProcessingPayment(false);
      }
    }
  };

  const fetchPromoCode = async () => {
    setPromoCodeStatus('LOADING');

    try {
      const { data } = await api.get(
        `/payments/coupons/${promoCodeValue}?price_id=${selectedPlan.id}`,
      );
      setCoupon({ promoCode: promoCodeValue, ...data.coupon });
      setPromoCodeStatus('SUCCESS');
    } catch (error) {
      setPromoCodeStatus('ERROR');
    }
  };

  const handlePromoCodeInput = (event) => {
    setPromoCodeStatus('');
    setCoupon(false);
    setPromoCodeValue(event.target.value);
  };

  const onPaymentSuccessful = async () => {
    const { data } = await api.get('/users/me');
    await dispatch({ type: 'currentUser/set', payload: data });
    setPurchaseSuccess(true);
    setProcessingPayment(false);
  };

  const visiblePlanFeatures =
    selectedPlan.id === 3 ? PLAN_FEATURES.slice(1) : PLAN_FEATURES;

  const firstScreenLeft = (
    <>
      <div className="top-content">
        <ResumeIcon className="resume-icon" />
        {isUnsubscribed ? (
          <KoiTitle>
            <Highlight whiteHighlight>Choose the plan</Highlight> that's right{' '}
            <br /> for you
          </KoiTitle>
        ) : (
          <KoiTitle>
            Start your <Highlight whiteHighlight>7-day free trial</Highlight> to
            download your resume.
          </KoiTitle>
        )}
      </div>
      <div className="bottom-content">
        <p className="label">All plans include:</p>
        <div className="items-container">
          {ITEMS.map((item, index) => (
            <div className="item" key={index}>
              <div>{item.icon}</div>
              <p className="item-label">{item.label}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  const secondScreenLeft = (
    <>
      <div className="top-content">
        <div
          className="arrow-icon-container"
          onClick={() => {
            setShowCardInfo(false);
          }}
        >
          <ArrowLeft />
        </div>
        <div className="selected-plan-details">
          <p className="selected-plan-label">Selected plan</p>
          <p className="main-title">{selectedPlan.title}</p>
          <p className="main-subtitle">{selectedPlan.info}</p>

          <div>
            <p>Includes:</p>
            {visiblePlanFeatures.map((feature) => (
              <div className="plan-feature-item">
                <CheckmarkIcon />
                <p>{feature}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer-info">
        {selectedPlan.free_trial && (
          <div className="flex-item">
            <p className="main-title">Due today</p>{' '}
            <p className="main-title green-label">$0</p>
          </div>
        )}
        <div className="flex-item">
          {selectedPlan.free_trial ? (
            <>
              <p>Billed on {addDaysToDate(7)}</p>
              <div className="display-flex">
                {coupon.percent_off && (
                  <p className="billed-on-price coupon-discount">
                    {selectedPlan.fullPrice}
                  </p>
                )}
                <p className="billed-on-price">
                  {coupon.percent_off
                    ? getDiscountedPrice(
                        selectedPlan.fullPrice,
                        coupon.percent_off,
                      )
                    : selectedPlan.fullPrice}
                </p>
              </div>
            </>
          ) : (
            <>
              <p className="main-title">Due today</p>
              <div className="display-flex">
                {coupon.percent_off && (
                  <p className="main-title coupon-discount">
                    {selectedPlan.fullPrice}
                  </p>
                )}

                <p className="main-title green-label">
                  {coupon.percent_off
                    ? getDiscountedPrice(
                        selectedPlan.fullPrice,
                        coupon.percent_off,
                      )
                    : selectedPlan.fullPrice}
                </p>
              </div>
            </>
          )}
        </div>

        <p className="disclaimer">{selectedPlan.disclaimer}</p>
      </div>
    </>
  );

  const firstScreenRight = (
    <>
      <div className="top-content">
        <div className="close-icon" onClick={onHide}>
          <CloseIcon />
        </div>
        <KoiTitle className="choose-plan-label">
          Choose your plan below.
        </KoiTitle>

        <div className="plans">
          {PLANS.map((plan) => (
            <div
              className={
                selectedPlan.title === plan.title
                  ? 'plan plan-selected'
                  : 'plan'
              }
              onClick={() => {
                Tracking.trackUserClicked({
                  widget_name: 'Pricing',
                  tier: plan.statName,
                  from_view: 'Plans modal',
                });
                setSelectedPlan(plan);
              }}
            >
              <div>
                <p className="plan-title">{plan.title}</p>
                <p className="plan-subtitle">{plan.subTitle}</p>
              </div>
              <div className="plan-price">
                <div className="flex">
                  <p className="price"> {plan.price} </p>{' '}
                  <p className="plan-period">{plan.period || ''}</p>
                </div>
                {selectedPlan.title === plan.title && (
                  <div className="plan-selected-icon-wrapper">
                    <CheckIcon />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="continue-btn-wrapper">
        <KoiButton
          className="continue-btn"
          variant="primary"
          disabled={!selectedPlan.id}
          onClick={() => {
            setShowCardInfo(true);
          }}
        >
          Continue
        </KoiButton>
      </div>
    </>
  );

  const secondScreenRight = (
    <>
      <div className="close-icon" onClick={onHide}>
        <CloseIcon />
      </div>
      <div className="choose-plan-label show">Payment method</div>

      <Form onSubmit={handleSubmit} className="paywall-form">
        <div className="top-content">
          <div className="card-number">
            <div className="stripe-label">Card number</div>
            <div className="input">
              <CardNumberElement
                options={{ style: STRIPE_STYLE, showIcon: true }}
              />
            </div>
          </div>

          <div className="date-cvc-container">
            <div className="expiry-date">
              <div className="stripe-label">Expiration date</div>
              <div className="input">
                <CardExpiryElement options={{ style: STRIPE_STYLE }} />
              </div>
            </div>
            <div className="cvc">
              <div className="stripe-label">Security code</div>
              <div className="input">
                <CardCvcElement options={{ style: STRIPE_STYLE }} />
              </div>
            </div>
          </div>
          <a
            className="powered-by-stripe"
            rel="__noreferrer"
            href="https://stripe.com"
          >
            <PoweredByStripe />
          </a>

          <hr />

          <div className="input promo-code-wrapper">
            <div className="promo-code-input">
              <TruepathInput
                label="Enter promo code"
                className={
                  promoCodeStatus === 'ERROR' ? 'invalid-code-input' : ''
                }
                value={promoCodeValue}
                onChange={handlePromoCodeInput}
              />
              <div className="promo-code-icon">
                {promoCodeStatus === 'SUCCESS' && <ValidIcon />}
                {promoCodeStatus === 'ERROR' && <InvalidIcon />}
              </div>
            </div>

            <KoiButton
              className="apply-btn ml-10"
              variant="secondary"
              onClick={fetchPromoCode}
              disabled={!promoCodeValue}
            >
              {promoCodeStatus === 'LOADING' ? <Spinner /> : 'Apply'}
            </KoiButton>
          </div>
          {promoCodeStatus === 'ERROR' && (
            <p className="invalid-code">Invalid code.</p>
          )}
        </div>

        <div className="bottom-container">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={reCaptchaSiteKey}
            size="invisible"
          />

          <div className="confirm-btn-wrapper">
            <KoiButton
              type="submit"
              className="confirm-btn"
              variant="primary"
              disabled={!selectedPlan.id || purchaseSuccess || error}
            >
              {purchaseSuccess ? (
                'Payment successful!'
              ) : processingPayment ? (
                <Spinner />
              ) : (
                'Confirm'
              )}
            </KoiButton>
          </div>
        </div>
      </Form>
    </>
  );

  return (
    <>
      <div className="__paywall">
        {purchaseSuccess ? (
          <SuccessfulPurchase
            selectedPlan={selectedPlan}
            subscription={subscriptionInfo}
            subscriptionType={subscriptionType}
            email={currentUser.email}
            onHide={onHide}
          />
        ) : (
          <>
            <div className="left">
              {!showCardInfo ? firstScreenLeft : secondScreenLeft}
            </div>
            <div className="right">
              {!showCardInfo ? firstScreenRight : secondScreenRight}
            </div>
          </>
        )}
      </div>
      {error && (
        <KoiToast
          type="danger"
          show
          message={error}
          onClose={() => setError(null)}
        />
      )}
    </>
  );
};

export default Paywall;
